.rahbariyat h1{
    font-weight: 400;
    text-align: center;
    font-size: 56px;
    color: #004737;
    padding-bottom: 71px;
}

.rahbariyat .p_one{
    font-size: 32px;
    font-weight: 400;
    color: #004737;
    width: 773px;
    line-height: 33px;
    padding-bottom: 8px;
    padding-top: 29px;
}

.rahbariyat .p_two{
    font-size: 39px;
    margin-top: 50px;
    
    margin-bottom:50px;
    color: #004737;
    font-weight: 500;
    line-height: 25px;
    padding-bottom: 36px;
}

@media(max-width: 989px){
    .rahbariyat  .p_two{
        text-align: center;
        line-height: 25px;
    }
}
@media(max-width: 732px){
    .rahbariyat .p_two{
        text-align: center;
        line-height: 29px;
    }
}
@media(max-width: 420px){
    .rahbariyat .p_two{
        margin: 0;
        text-align: center;
        line-height: 20px;
        font-size:20px;
    }
    .rahbariyat .for_rahbariyat .p_four{
        font-size:16px !important;
        padding: 0 !important; 
    }
    .rahbariyat .for_rahbariyat .p_five{
        font-size: 17px !important;
        padding: 0 !important; 
    }
    .rahbariyat .for_rahbariyat .rahbariya_1 .p_sixs{
        font-size: 18px !important;
        padding: 0 !important; 
        
    }
}

.rahbariyat .for_rahbariyat{
    background-color: #F4F9F8;
}

.rahbariyat .for_rahbariyat .p_three{
    font-size: 32px;
    font-weight: 400;
    color: #004737;
    line-height: 38px;
    padding-bottom: 43px;
}

.rahbariyat .for_rahbariyat img{
    width: 100%;
    /* height: 100%; */
    
}

.rahbariyat .for_rahbariyat .p_four{
    font-size: 18px;
    font-weight: 400;
    color: #669187;
    
}

.rahbariyat .for_rahbariyat .p_five{
    font-size: 20px;
    font-weight: 500;
    color: #004737;
    padding-bottom: 20px;
    line-height: 30px;
}

.rahbariya_1{
    padding-bottom: 150px;
    padding: 32px 24px;
}



.rahbariyat .for_rahbariyat .rahbariya_1 .p_sixs{
    font-size: 24px;
    font-weight: 500;
    color: #004737;
    padding-bottom: 20px;
    line-height: 30px;
}