.korrupsion{
    width: 100%;
}

.korrupsion h1{
    font-weight: 400;
    font-size: 36px;
    color: #004737;
    text-align: center;
    line-height: 44px;
}

.korrupsion img{
    width: 100%;
    margin-top: 55px;
}

.korrupsion .vxod{
    font-size: 64px;
    font-weight: 500;
    color: #004737;
    text-transform: capitalize;
    margin-top: 100px;
    line-height: 74px;
}

@media(max-width:992px){
    .korrupsion .vxod{
    font-size: 32px;
    line-height: 44px;
    }
    .classname_card_2{
        margin-left: 0px;
    }

    .korrupsion .vxod_2{
        margin-top: 10px !important;
    }

    .korrupsion .korrupsionimg3{
        margin-top: -10px!important;
        width: 70%;
        margin-left: 15%;
    }

    .korrupsion .vxod22{
        margin-top: 30px!important;
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    .korrupsion .vxod33{

        font-size: 28px;
        margin-top: 30px;
    }

    .korrupsion .xuquqning{
        margin-top: 10px;
    }

    .korrupsion h1{
        font-size: 32px;
    }
   .korrupsion .p_table_corrupsion{
        font-size: 24px;
    }

    .image_rectinge{
        height: 619px!important;
    }

    .classname_card{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .classname_card_2{
        margin-left: 0px!important;
    }

    .for_grid{
        padding-left: 120px;
    }

    .korrupsion{
        padding-left: 12px;
        padding-right: 12px;
    }
}
@media(max-width:636px){
    .korrupsion .vxod{
    font-size: 24px;
    }

    .korrupsion h1{
        font-size: 24px;
        line-height: 33px;
    }

   .korrupsion .p_table_corrupsion{
        font-size: 20px;
    }
    .korupsionimage img{
        margin-top: 10px!important;
    }
    .for_lobbizm{
        margin-top: 0px!important;
    }

    .vxod{
        margin-top: 20px!important;
        line-height: 30px!important;
    }
}

@media(max-width:558px){
    .for_grid{
        padding-left: 100px;
    }
}
@media(max-width:513px){
    .for_grid{
        padding-left: 90px;
    }
}
@media(max-width:488px){
    .for_grid{
        padding-left: 80px;
    }
}
@media(max-width:468px){
    .for_grid{
        padding-left: 70px;
    }
}
@media(max-width:453px){
    .for_grid{
        padding-left: 60px;
    }
}
@media(max-width:432px){
    .for_grid{
        padding-left: 50px;
    }
}
@media(max-width:419px){
    .for_grid{
        padding-left: 40px;
    }
}
@media(max-width:400px){
    .for_grid{
        padding-left: 30px;
    }
}

@media(max-width:383px){
    .for_grid{
        padding-left: 20px;
    }
}
@media(max-width:360px){
    .for_grid{
        padding-left: 10px;
    }
}


@media(max-width:450px){
    .korrupsion .vxod{
    font-size: 18px;
    }

    .korupsionimage img{
        margin-top: -20px!important;
    }

    .left_frstvsds{
        font-size: 18px!important;
    }
    .right_frstvsds{
        font-size: 18px!important;
    }

    .vxod33{
        font-size: 20px!important;
    }

    .texting_for_la{
        font-size: 18px!important;
    }

    .vxod22{
        font-size: 20px!important;
    }

    .text_right_president{
        font-size: 16px!important;
        line-height: 23px!important;
    }

    .markazuchun{
        font-size: 20px!important;
        line-height: 26px!important;
    }

    .ulling{
        padding-left: 10px!important;
    }

    .ulling li{
        font-size: 18px!important;
    }

    .korrupsion h1{
        font-size: 18px;
    }
   .korrupsion .p_table_corrupsion{
        font-size: 15px;
    }
    .korupsionimage{
        margin-top: 0px!important;
    }

    .p_table_corrupsion{
        font-size: 18px!important;
    }

    .korrupsion h1{
        line-height: 30px;
    }
}



.korrupsion .p_table_corrupsion{
    font-size: 28px;
    font-weight: 400;
    color: #336C5F;
}

.tabling{
    width: 100%;
    border-top: 1px solid #669187;
}

.tabling .second_korrupsion{
    border-right: 1px solid #669187;
}

.vxod_2{
    margin-top: 160px !important;
}

.vxod-2{
    margin-top: 25px;
    font-size: 18px;
    font-weight: 400;
    color: #336C5F;
}

.korupsionimage img{
    margin-top: 190px;
    width: 100%;
}

.text_about_korupsion{
    font-size: 18px;
    font-weight: 300;
    color: #004737;
    margin-bottom: 37px;
}

.korrupsionimg3{
    width: 100%;
    margin-top: 108px!important;
}

.for_lobbizm{
    margin-top: 99px !important;   
}

.number_korrupsion{
    font-size: 24px;
    font-weight: 500;
    color: #004737;
}

.korrupsion_num{
    font-size: 18px;
    font-weight: 400;
    color: #336C5F;
}

.omillar_korrupsion p{
    text-align: left;
    /* margin-top: 98px; */
}

.omillar_korrupsion img{
    width: 100%;
    margin-top: 10px;
}

.img_korrupsion_three{
    width: 247px;
    height: 177px;
}

.frtsvds{
    margin-top: 98px !important;
}

.frstvds2{
    margin-top: 98px !important;
}

.frst_line{
    margin-top: 55px;
    margin-bottom: 25px;
}

.right_papka{
    margin-left: 30px;
    margin-top: 55px!important;
}

.classname_card{
    border: 0.5px solid #004737;
    width: 310px!important;
    height: 385px; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.classname_card2{
    
}

.xuquq_text{
    font-size: 24px;
    color: #004737;
    font-weight: 500;
}

.asosi_xuquq{
    font-size: 17px;
    color: #336C5F;
    font-weight: 400;
}

.for_grid{
    gap: 5px;
}

.for_four_card{
    margin-top: 50px!important;
}

.classname_card_2{
    margin-left: 320px;
}

.maishiy{
    /* text-align: center!important; */
    margin: auto;
    font-size: 24px;
    color: #004737;
    font-weight: 500;
}

.vxod22{
    margin-top: 185px !important;
    font-weight: 400;
    font-size: 36px;
    text-align: center;
    color: #004737;
    line-height: 43px;
}

.xuquqning{
    margin-top: 45%;
}

.for_landing_etxt p{
    font-size: 24px;
    font-weight: 500;
    color: #336C5F;
    margin-top: 50px;
}

.for_landing_etxt .left_frstvsds{
    border-right: 1px solid #336C5F;
}

.for_landing_etxt .right_frstvsds{
    border-left: 1px solid #336C5F;
    padding-left: 10px;
    margin-left: -15px;
}

.vxod33{
    font-size: 36px;
    font-weight: 400;
    color: #004737;
}

.text_right_president{
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #004737;
    text-align: right;
}

.frst_lineee{
    margin-top: 30px!important;
}

.texting_for_la{
    font-size: 24px;
    color: #004737;
    font-weight: 500;
}

.for_justify{
    display: flex;
    justify-content: flex-end;
}

.text_oo{
    font-size: 36px;
    font-weight: 400;
    color: #669187;
    text-align: right;
}

.korrupsion_after{
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #004737;
}

.ulling{
    color: #004737;
    margin-top: 40px;
    
    
}

.ulling li{
    font-size: 24px;
    color: #004737;
    font-weight: 500;
    margin-bottom: 30px;
    list-style-type: square!important;
}

.image_rectinge{
    width: 100%;
    height: 719px;
}

.topdan-olsh{
    margin-top: 30px;
}

.markazuchun{
    font-size: 24px;
    color: #004737;
    font-weight: 400;
    line-height: 33px;
    margin-top: 45px;
}