.news-container {
    padding-left: 200px ;
    padding-right: 200px ;
}

.news-text-container {
    padding-left: 50px;
}

.news-title {
    font-size: 28px
}

.news-description {
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: justify;
}