.question-card{
    border-top: 1px solid #004737;
    border-bottom: 1px solid #004737;
    font-size: 16px;
    text-align: justify;
}

.question-card-pointer {
    color: #004737;
    font-size: 28px;
    cursor: pointer;
}

.table-text{
    color: #004737;
    font-size: 16px;
}